<template>
  <div>
    <div v-if="isLoading" class="text-center">
      <p>
        {{ message }}
      </p>
      <v-progress-linear :value="progress" color="primary" height="20" rounded stream striped/>
    </div>
  </div>
</template>

<script>
export default {
    name: "Progression",
    props: ['isLoading', 'message'],
    data() {
        return {
            minutes: 30,
            progress: 0,
            countdown: null
        }
    },
    methods: {
        startTimer() {
            let seconds = this.minutes
            this.countdown = setInterval(() => {
                this.minutes--
                this.progress = ((seconds - this.minutes) * 300) / seconds
                if (this.minutes === 0) {
                    this.stopTimer()
                }
            }, 150)
        },
        stopTimer() {
            clearInterval(this.countdown)
            this.minutes = 300
        },
        reset() {
            this.progress = 0
            this.stopTimer()
        },
    },
}
</script>

<style scoped>

</style>