<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="10">
          <v-card :disabled="isLoading" class="shadow rounded-lg">

            <v-card-title>
              Créer une nouvelle campagne
              <v-spacer/>
              <v-btn color="red"
                     exact
                     left
                     text
                     to="/campaigns">
                <v-icon color="red" left>mdi-chevron-left</v-icon>
                Retour
              </v-btn>
            </v-card-title>

            <v-card-subtitle>
              Tous les champs indiqués avec une étoile (*) sont obligatoires.
            </v-card-subtitle>

            <v-divider/>

            <v-card-text class="pa-10">

              <v-alert v-if="Object.keys(errors).length"
                       border="top"
                       dismissible
                       prominent
                       text
                       type="error">
                <ul class="fs-15">
                  <li v-for="(err,index) in errors" :key="index">
                    {{ err.toString() }}
                  </li>
                </ul>
              </v-alert>

              <v-text-field v-model="form.title"
                            :error-messages="errors.title"
                            dense
                            label="Titre (*)"
                            outlined
                            placeholder="Écrivez le titre de la campagne ici...">
              </v-text-field>

              <v-textarea v-model.trim="form.description"
                          :error-messages="errors.description"
                          clearable
                          label="Description (optionnel)"
                          outlined
                          placeholder="Écrivez une description ici..."
                          prepend-inner-icon="mdi-pencil-box-outline"
                          rows="3"></v-textarea>

              <div class="mb-2">
                La première colonne doit être le code proprietaire
                <a class="text-decoration-underline orange--text text--darken-2"
                   @click="downloadExample()">Télécharger le model</a>
              </div>

              <v-file-input :error-messages="errors.excel_file"
                            accept=".xlsx, .xls"
                            chips
                            class="autocomplete"
                            dense
                            label="Fichier excel pour plus d'information sur le propriétaire (Optionnel)"
                            outlined
                            small-chips
                            @change="uploadFile">
              </v-file-input>


              <div class="d-flex justify-space-between align-center mb-2">
                <v-chip class="bg-primary-subtle" small text-color="secondary">
                  Tapez &nbsp; <strong>#</strong> &nbsp; pour afficher les mots-clés à remplacer dans les SMS
                </v-chip>
                <v-btn-toggle v-model="dir" color="primary" rounded>
                  <v-btn active-class="primary white--text" small text value="ltr">
                    FR
                  </v-btn>
                  <v-btn active-class="primary white--text" small text value="rtl">
                    AR
                  </v-btn>
                </v-btn-toggle>
              </div>

              <v-textarea ref="textarea"
                          v-model.trim="form.sms"
                          :class="dir === 'rtl' ? 'ar':''"
                          :dir="dir"
                          :error-messages="errors.sms"
                          :label="dir === 'rtl' ? 'الرسالة القصيرة (*)':'SMS (*)'"
                          :placeholder="dir === 'rtl' ? 'اكتب الرسالة القصيرة هنا...':'Écrivez le SMS ici...'"
                          clearable
                          outlined
                          prepend-inner-icon="mdi-cellphone-message"
                          rows="3"
                          @keydown="handleKeydown($event)"></v-textarea>

              <v-autocomplete v-model="form.databases"
                              :error-messages="errors.databases"
                              :items="databases"
                              :label="!form.excel_file ?  'Bases de données (*)' : 'Bases de données (optionnel)'"
                              chips
                              class="autocomplete"
                              deletable-chips
                              dense
                              hide-details="auto"
                              item-text="name"
                              item-value="id"
                              multiple
                              outlined>
              </v-autocomplete>


              <v-switch v-model="form.send_now" :hide-details="form.send_now" label="Envoyer maintenant"></v-switch>

              <v-row v-if="!form.send_now">
                <v-col cols="8">
                  <v-menu v-model="dateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                              v-model="form.scheduled_date"
                              dense
                              hide-details="auto"
                              label="Date d'envoi (*)"
                              outlined
                              prepend-inner-icon="mdi-calendar-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:prepend-inner="dateMenu=true"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.scheduled_date"
                                   :min="form.scheduled_date"
                                   @input="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>

                </v-col>
                <v-col cols="4">
                  <v-menu ref="timeMenu"
                          v-model="timeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.scheduled_time"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                              v-model="form.scheduled_time"
                              dense
                              hide-details="auto"
                              label="Heur d'envoi (*)"
                              outlined
                              prepend-inner-icon="mdi-clock-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:prepend-inner="timeMenu=true"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-model="form.scheduled_time"
                                   format="24hr"
                                   @click:minute="$refs.timeMenu.save(form.scheduled_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <Progression ref="progression"
                           v-if="isLoading"
                           :is-loading="isLoading"
                           :message="'Enregistrement en cours, veuillez patienter...'"
                           class="py-5"/>

            </v-card-text>


          </v-card>

          <div class="pt-5 text-right">
            <v-btn :disabled="disabledSaveBtn() || isLoading"
                   color="secondary"
                   depressed
                   @click="$refs.resultsDialog.open()">
              <v-icon left>mdi-content-save</v-icon>
              Enregistrer
            </v-btn>
          </div>

        </v-col>
      </v-row>

      <ResultsDialog ref="resultsDialog"
                     :dir="dir"
                     :form="form"
                     @errors="errors = $event"
                     @save="send($event)"/>

      <TagsDialog ref="tagsDialog"
                  :excel-keys="excelKeys"
                  :form.sync="form"
                  :textarea-refs="$refs.textarea"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from 'moment'
import * as XLSX from 'xlsx'
import TagsDialog from "@/views/campaigns/create/components/TagsDialog.vue";
import ResultsDialog from "@/views/campaigns/create/components/ResultsDialog.vue";
import Progression from "@/components/Progression.vue";

export default {
    components: {Progression, ResultsDialog,TagsDialog},
    data() {
        return {
            dir: 'ltr',
            isLoading: false,
            databases: [],
            errors: {},
            excelKeys: [],
            dateMenu: false,
            timeMenu: false,
            form: {
                id: '',
                title: '',
                excel_file: '',
                sms: '',
                description: '',
                scheduled_date: moment().format('YYYY-MM-DD'),
                scheduled_time: moment().add(10, 'minutes').format('HH:mm'),
                send_now: true,
                databases: [],
            }
        }
    },
    methods: {
        getDatabases() {
            HTTP.get('/database').then((res) => {
                this.databases = res.data.data.filter(el => el.is_active === 1)
            }).catch(err => {
                console.log(err)
            })
        },
        send(type) {
            this.isLoading = true

            this.$nextTick(() => {
                this.$refs.progression.reset()
                this.$refs.progression.startTimer()
            })

            this.errors = {}

            let formData = new FormData()

            if (this.$route.params.id) {
                formData.append('id', this.form.id)
            }

            formData.append('title', this.form.title)
            formData.append('excel_file', this.form.excel_file)
            formData.append('sms', this.form.sms)
            formData.append('description', this.form.description)
            formData.append('send_now', this.form.send_now)
            formData.append('databases', this.form.databases)
            formData.append('scheduled_datetime', this.form.scheduled_date + ' ' + this.form.scheduled_time)

            this.form.databases.forEach(function (id) {
                formData.append('databases[]', id);
            })

            HTTP({
                url: this.form.id ? '/campaigns/' + this.form.id + '/update' : '/campaigns/store',
                method: 'POST',
                data: formData,
            }).then(() => {
                if (type === 'back') {
                    this.$router.push('/campaigns')
                }
                this.$successMessage = 'Cette campagne a été enregistrée avec succès'
                this.isLoading = false
                this.$refs.progression.reset()

                this.form = {
                    id: '',
                    title: '',
                    excel_file: '',
                    sms: '',
                    description: '',
                    scheduled_date: '',
                    scheduled_time: '',
                    send_now: '',
                    databases: [],
                }

            }).catch(err => {
                this.isLoading = false
                this.$refs.progression.reset()
                this.errors = err.response.data.errors
                this.$vuetify.goTo(0)
                console.log(err)
            })
        },
        excelFileToJSON(file) {
            let reader = new FileReader();
            reader.readAsBinaryString(file);
            return new Promise((resolve) => {
                reader.onload = function (e) {
                    let data = e.target.result;
                    let workbook = XLSX.read(data, {
                        type: "binary",
                    });
                    let result = {};
                    workbook.SheetNames.forEach(function (sheetName) {
                        let roa = XLSX.utils.sheet_to_row_object_array(
                            workbook.Sheets[sheetName]
                        );
                        if (roa.length > 0) {
                            result[sheetName] = roa;
                        }
                    });
                    resolve(result);
                };
            });
        },
        async uploadFile(file) {
            if (file) {
                this.form.excel_file = file
                let jsonResult = await this.excelFileToJSON(file)
                let firstKey = Object.keys(jsonResult)[0]
                let excelKeys = (jsonResult[firstKey].map(x => Object.keys(x))[0])
                this.excelKeys = excelKeys.slice(1)
            } else {
                this.excelKeys = []
                this.form.sms = ''
                this.form.excel_file = ''
            }
        },
        handleKeydown(e) {
            if (e.key === '#') {
                this.$refs.tagsDialog.open()
            }
        },
        downloadExample() {
            window.open(process.env.VUE_APP_BASE_URL + '/exampleCampaign.xlsx', '_blanc')
        },
        disabledSaveBtn() {
            if (!this.form.sms || this.form.sms.length <= 3) {
                return true
            }
        }
    },
    created() {
        this.getDatabases()
    }
}
</script>

<style>

</style>