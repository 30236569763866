<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" scrollable>
      <v-card>
        <v-card-title>
          Mots-clés
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-row>
            <v-col cols="6">
              <v-card class="rounded-lg" outlined>
                <v-card-title>
                  <v-icon left>mdi-database-outline</v-icon>
                  <span class="fs-14">Base de données</span>
                </v-card-title>
                <v-divider/>

                <v-list dense>
                  <v-list-item v-for="(key,index) in dbKeys" :key="index"
                               @click="addTag('{{'+key.value+'}}')">
                    <v-list-item-title>{{ key.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>

              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card :disabled="!excelKeys.length" class="rounded-lg" outlined>
                <v-card-title class="fs-14">
                  <v-icon left>mdi-file-excel-outline</v-icon>
                  <span class="fs-14">Excel</span>
                </v-card-title>
                <v-divider/>

                <v-list dense>
                  <v-list-item v-for="key in excelKeys"
                               :key="key"
                               @click="addTag('{{'+key.trim()+'}}')">
                    <v-list-item-title>{{ key }}</v-list-item-title>
                  </v-list-item>
                </v-list>

              </v-card>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    props: ['excelKeys', 'textareaRefs', 'form'],
    data() {
        return {
            dialog: false,
            dbKeys: [
                {
                    name: 'Code',
                    value: 'Code'
                },
                {
                    name: 'Nom du propriétaire',
                    value: 'Nom_du_propriétaire'
                },
                {
                    name: 'Nom du magasin',
                    value: 'Nom_du_magasin'
                },
                {
                    name: 'N° Téléphone',
                    value: 'N°_Téléphone'
                },
                {
                    name: 'Type',
                    value: 'Type'
                },
            ],
        }
    },
    methods: {
        open() {
            this.dialog = true
        },
        addTag(insertText) {

            if (!insertText.length) return

            let text = insertText.replaceAll(' ', '_')

            const textarea = this.textareaRefs.$refs.input
            const sentence = textarea.value
            const len = sentence.length
            let pos = textarea.selectionStart
            if (pos === undefined) {
                pos = 0
            }

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, len)

            this.form.sms = before + text + after

            this.$nextTick().then(() => {
                textarea.selectionStart = pos + text.length
            })

            this.dialog = false
        },
    }
}
</script>

<style scoped>

</style>