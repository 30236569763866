<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" persistent scrollable>
      <v-card :disabled="isLoading">
        <v-card-title>
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <Progression ref="progression"
                     class="pa-15"
                     v-if="isLoading"
                     :is-loading="isLoading"
                     :message="'Traitement en cours, veuillez patienter...'"/>

        <v-card-text v-if="!isLoading" class="pa-4 text-center fs-15">

          <v-avatar class="bg-warning-subtle" size="100">
            <v-icon color="warning" size="50">mdi-information</v-icon>
          </v-avatar>

          <h2 class="my-5 secondary--text font-weight-medium">CONFIRMATION</h2>

          <p>Vous ne pourrez pas revenir en arrière !</p>

          <div class="d-flex justify-space-between mt-15">
            <div>
              <span class="font-weight-medium">Total propriétaires :</span>
              <span class="red--text font-weight-medium">
                     {{ analytic.total_sms }}
                </span>
            </div>
            <div>
              <span class="font-weight-medium">Coût Total :</span>
              <span class="red--text font-weight-medium">
                  {{ analytic.cost_sms | toCurrency }} DZD
                </span>
            </div>
          </div>


          <v-expansion-panels :disabled="!analytic.salepoints.length" class="py-5" flat>
            <v-expansion-panel class="table-border rounded-lg pa-2">
              <v-expansion-panel-header class="font-weight-medium">
                <div>
                  <v-icon color="primary" left>mdi-email-multiple-outline</v-icon>
                  Exemples des SMS que vous enverrez
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table class="table-border rounded-lg">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Propriétaire</th>
                      <th>SMS</th>
                      <th class="text-end">Coût</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,i) in analytic.salepoints" :key="i">
                      <td class="text-start">
                        <div class="d-flex text-no-wrap align-center py-2" :class="$func.containsArabic(item.name) &&  'ar'">

                          <v-avatar v-if="item.image" size="40">
                            <v-img :src="$baseUrl + item.image"></v-img>
                          </v-avatar>

                          <v-avatar v-else
                                    :class="$func.randomColor()"
                                    class="font-weight-medium"
                                    size="40">
                            {{ $func.avatarName(item.name) }}
                          </v-avatar>

                          <span :class="$func.containsArabic(item.name) ?  'mr-2' : 'ml-2'">
                          {{ item.name }}
                          <span class="font-weight-medium red--text d-block">
                            <v-icon color="red" small>mdi-phone</v-icon>
                            {{ item.phone }}
                          </span>
                        </span>
                        </div>
                      </td>
                      <td>
                        <div :class="dir === 'rtl' ? 'ar':''"
                             class="py-2 text-start">
                          {{ item.sms }}
                        </div>
                      </td>
                      <td class="text-end">
                            <span class="font-weight-medium red--text d-block text-no-wrap">
                            {{ item.sms_cost | toCurrency }} DZD
                             </span>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-card-text>

        <v-card-actions v-if="!isLoading" class="grey lighten-3">
          <v-spacer/>

          <v-btn v-if="!$route.params.id"
                 :disabled="!analytic.total_sms"
                 class="mr-2"
                 color="secondary"
                 text @click="[dialog = false,$emit('save','new')]">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer et nouveau
          </v-btn>

          <v-btn :disabled="!analytic.total_sms"
                 color="secondary"
                 depressed
                 @click="[dialog = false,$emit('save','back')]">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer {{ $route.params.id ? '' : 'et retour' }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import Progression from "@/components/Progression.vue";

export default {
    components: {Progression},
    props: ['form', 'dir'],
    data() {
        return {
            dialog: false,
            isLoading: false,
            analytic: {
                total_sms: 0,
                cost_sms: 0,
                salepoints: [],
            },
        }
    },
    methods: {
        open() {
            this.dialog = true
            this.showSmsCost()
        },
        showSmsCost() {
            this.isLoading = true

            this.$nextTick(() => {
                this.$refs.progression.reset()
                this.$refs.progression.startTimer()
            })

            let formData = new FormData()

            formData.append('excel_file', this.form.excel_file)
            formData.append('sms', this.form.sms)

            this.form.databases.forEach(function (id) {
                formData.append('databases[]', id);
            })

            HTTP({
                url: "/campaigns/check-sms-cost",
                method: "post",
                data: formData,
            }).then((res) => {
                this.isLoading = false
                this.$refs.progression.reset()
                this.analytic = res.data.data
            }).catch((err) => {
                this.isLoading = false
                this.$refs.progression.reset()
                this.$emit('errors', err.response.data.errors)
                console.log(err)
            })
        },
    }
}
</script>

<style scoped>

</style>